export const calculateModelPosition = (width, height) => {
    let twidth=0, theight=0, mwidth=width, mheight=height;

    if (mwidth > window.innerWidth) mwidth = window.innerWidth - 5;
    if (mheight > window.innerHeight) mheight = window.innerHeight - 5;
    
    twidth =Math.floor((window.innerWidth - 50 - mwidth) / 2);
    if (twidth < 0) twidth = 0;
    theight =Math.floor((window.innerHeight -200 - mheight) / 2);
    if (theight < 0) theight = 0;

    console.log('calculateModelPosition', window.innerWidth, window.innerHeight, twidth, theight, mwidth, mheight)
    return([twidth, theight, mwidth, mheight]);
};

export const setAppNotification = (msg, displayTimeInSec=3) => {
    console.log('setAppNotification', msg, displayTimeInSec);

    document.getElementById('AppNotificationBox').innerHTML = msg;
    document.getElementById('AppNotificationBox').style.visibility = 'visible';

    setTimeout( () => {
      document.getElementById('AppNotificationBox').style.visibility = 'hidden';
    }, displayTimeInSec*1000)
}

export default calculateModelPosition;