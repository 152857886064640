import './App.css';
// import LeftPanel from './LeftPanel';

import { useEffect, useState } from 'react';
import Configuration from './Configuration';
import SessionGroup from './SessionGroup';
import { AiOutlineTrademark } from 'react-icons/ai';
import { GiHamburgerMenu, GiWeightLiftingUp } from 'react-icons/gi';
import { FaArrowCircleUp, FaArrowCircleDown } from "react-icons/fa";
import { TiTimes } from "react-icons/ti";
import { IoSave } from "react-icons/io5";
import { BiDumbbell } from "react-icons/bi";
import axios from 'axios';
import {setAppNotification, calculateModelPosition} from './modalFunction';

// google analytics
import ReactGA from 'react-ga4';
import MatchHistory from './MatchHistory';
ReactGA.initialize('G-392VB88H5H');



// Training Days
const trainingDays = ['Monday', 'Wednesday', 'Thursday', 'Friday'];
// ***

// Training Day Indexes
let trainingDayIndexes = [];
for (let i = 0; i < trainingDays.length; i++) {
  trainingDayIndexes[trainingDays[i]] = i;
}
// ***

// Trainer Group
// 0 - Monday, 1 - Wednesday, 2 - Thursday, 3 - Friday
const trainerGroups = [
  //index 0 for Monday
  ['Kok Hing', 'Nike', 'France', 'Kanice', 'Surain', 'Matmi', 'Sidney', 'An', 'Jj', 'Jajo'],
  //index 1 for Wednesday
  ['Nori', 'Chong', 'Shin', 'Win', 'Charles', 'Toby', 'Jasmine', 'Emma', 'Elton', 'An'],
  //index 2 for Thursday
  ['Pearl', 'Ray', 'Pauline', 'Jone', 'Angela', 'CY', 'Jeff', 'Vic', 'Abigail', 'Marcus', 'Amanda'],
  //index 3 for Friday
  ['April', 'Ramesh', 'Eve', 'Chan', 'Flor', 'Shigen', 'Nee Loon', 'Gil', 'Mel']
];

let globalBuddyTrainerMap = [];
// Buddies Names
let sessionBuddyGroups = [
  // index [0] for Monday
  [
    // index[0][0] for 6:45pm 
    ['Husky', 'Lean', 'Tenna', 'Alice', 'Poh Li', 'Bryan', 'Tong Ling', 'Kun Wei', 'Wilson', 'Isabelle'],
    // index[0][1] for 7:45pm
    ['Angel', 'Tony', 'Kenneth', 'Rebecca', 'Shyan', 'Samantha', 'Kiran', 'Adriel', 'Lideon', 'Seng Nuan'],
  ],
  // index [1] for Wednesday
  [
    // index[1][0] for 6:45pm
    ['Lean', 'Girui', 'Arthur', 'Angeline', 'Ben', 'Alicia', 'Ryan', 'Sher Luan', 'Peng Luan', 'Hui Ping'],
    // index[1][1] for 7:45pm
    ['Bei Wang', 'Jin', 'Kenneth', 'Samuel', 'Bing Mui', 'Chris', 'Mian Dou', 'Alex', 'Ben', 'Tong Soon', 'Jane'],
  ],
  // index [2] for Thursday
  [
    // index[2][0] for 6:45pm
    ['Ben', 'Gabriel', 'Sun Wen', 'Dhyan', 'Simon', 'Bond', 'Mong Ling'],
    // index[2][1] for 7:45pm
    ['Allan', 'Rui Min', 'April', 'Gideon', 'Kunling', 'Amaline', 'Lai Num']
  ],
  // index [3] for Friday
  [
    // index[3][0] for 6:45pm
    ['Husky', 'Sean', 'Sabrina', 'Leng Sheng', 'Rachel', 'Judith', 'Samuel', 'Eliza'],
    // index[3][1] for 7:45pm
    ['Kong Boon', 'Tony', 'Amaline', 'David', 'Adriel', 'Reena', 'Dan', 'Sai'],
  ]
]

let buddyNames = sessionBuddyGroups[0];
// ***  

// for side bar
let isNavOpen = false;

let mouseLocation = [0,0];

function App() {
  // google analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // handleGAButtonClick('User', 'Clicked a button');
  const handleGAButtonClick = (category, action) => {
    ReactGA.event({
      category: category,
      action: action
    });
  };

  // Display of Left Panel
  const [centrePanelDisplay, setCentrePanelDisplay] = useState("SessionPanel")

  const onClickLeftPanelSession = () => {
    // console.log('onClickLeftPanelSession');
    setCentrePanelDisplay('SessionPanel');
    controlNav();
    handleGAButtonClick('Clicked', 'LeftPanel Session');
  }

  const  onClickLeftPanelConfiguration = () => {
    // console.log('onClickLeftPanelConfiguration');
    setCentrePanelDisplay('ConfigurationPanel');
    controlNav();
    handleGAButtonClick('Clicked', 'LeftPanel Configuration');
  }

  const  onClickLeftPanelHistory = () => {
    setCentrePanelDisplay('HistoryPanel');
    controlNav();
    handleGAButtonClick('Clicked', 'LeftPanel History');
  }

  const  onClickLeftPanelMyPresentation = () => {
    // setCentrePanelDisplay('HistoryPanel');
    controlNav();
    handleGAButtonClick('Clicked', 'LeftPanel My Presentation');
  }

  const [trainingDay, setTrainingDay] = useState (trainingDays[0])
  let trainerNames = trainerGroups[[trainingDayIndexes[trainingDay]]];
  const [selectedTrainerNames, setSelectedTrainerNames] = useState(trainerGroups[trainingDayIndexes[trainingDay]]);
  const [buddyTrainerMap, setBuddyTrainerMap] = useState(globalBuddyTrainerMap);
  const sessionGroupExpand = [useState(true), useState(false)];

  const daySessions = ['6:45pm', '7:45pm'];

  const [isHeaderDisplayOpen, setHeaderDisplayOpen] = useState(true);

  // Training Day Selection
  const onChangeTrainingDay = e => {
    const day = e.target.value;
    console.log('onChangeTrainingDay.day', day);

    // trainingDay = day;
    setTrainingDay(day);
    setAppNotification('Selected ' + day);

    trainerNames = trainerGroups[trainingDayIndexes[day]];
    setSelectedTrainerNames(trainerNames);
    buddyNames = sessionBuddyGroups[trainingDayIndexes[day]];
    globalBuddyTrainerMap = [];
    setBuddyTrainerMap(globalBuddyTrainerMap);
    console.log('onChangeTrainingDay', buddyNames);
    handleGAButtonClick('Clicked', 'Change training day');
  }
  // ***

  const onClickAddNewTrainer = name => {
    //console.log('addNewTrainer.name', name)
    if (name !== '') {
      trainerNames.push(name);
    }
    //console.log('addNewTrainer.trainer_names', trainer_names)
    handleGAButtonClick('Clicked', 'Add new trainer');
  }

  const onClickTrainerNameButton = e => {
    const name = e.target.textContent;
    // console.log('onClickTrainerName:name', name);

    // to remove all entries of deselected trainer name from BuddyTrainerMap
    for (let i = 0; i < daySessions.length; i++) {
      for (let j = 0; j < buddyNames[i].length; j++) {
        let buddy = buddyNames[i][j];
        //console.log('onClickTrainerNameButton.buddy', i, j, buddy, BuddyTrainerMap.length, BuddyTrainerMap[buddy]);

        if (globalBuddyTrainerMap[buddy] === name) {
          //console.log('onClickTrainerNameButton.remove_trainer', BuddyTrainerMap[buddy]);
          //globalBuddyTrainerMap[buddy] = '';
          globalBuddyTrainerMap[buddy] = null;
          setBuddyTrainerMap(globalBuddyTrainerMap);
        }
      }
    }

    // refresh the trainer list in dropdown
    let names = [];
    for (let i = 0; i < trainerNames.length; i++) {
      //console.log('onClickTrainerName:for', i, trainer_names[i], selected_trainer_names);
      //console.log('#trainer_names[i] in selected_trainer_names', trainer_names[i], selected_trainer_names, selected_trainer_names.includes(trainer_names[i]))
      if (selectedTrainerNames.includes(trainerNames[i])) {
        if (trainerNames[i] === name) {
          // to remove from selected list
          //console.log('# clicked + selected trainer = remove', trainer_names[i]);
          ;
        } else {
          //console.log('# not clicked + select trainer = add', trainer_names[i])
          names.push(trainerNames[i]);
        }
      } else {
        if (trainerNames[i] === name) {
          //console.log('# clicked + not selected trainer = add', trainer_names[i])
          names.push(trainerNames[i]);
        } else {
          //console.log('# not clicked + not selected trainer = remove', trainer_names[i])
        }
      }
    }

    setSelectedTrainerNames(names);
    //console.log('onClickTrainerName:names', names);
    //console.log('onClickTrainerName:selected_trainer_names', selected_trainer_names);

    handleGAButtonClick('Clicked', 'Toggle trainer availability');
  }

  const addNewBuddy = (name, trainingTime) => {
    //console.log('addNewBuddy.name', name, trainingday, trainingday_indexes[trainingday], session_buddy_groups)
    if (name !== '') {
      //if (trainingtime === '6:45pm') session_buddy_groups[trainingday_indexes[trainingday]][0].push(name)
      //if (trainingtime === '7:45pm') session_buddy_groups[trainingday_indexes[trainingday]][1].push(name)
      if (trainingTime === '6:45pm') buddyNames[0].push(name);
      if (trainingTime === '7:45pm') buddyNames[1].push(name);
    }
    //console.log('addNewBuddy.buddy', session_buddy_groups[trainingday_indexes[trainingday]][0])
    handleGAButtonClick('Clicked', 'Add new buddy');
  }

  const onClickDeleteBuddy = (name) => {
    // remove buddy from buddy array for that day
    if (name !== '') {
      for (let i = 0; i < daySessions.length; i++) {
        //for (let j = 0; j < session_buddy_groups[trainingday_indexes[trainingday]][i].length; j++) {
        for (let j = 0; j < buddyNames[i].length; j++) {
          //console.log('onClickDeleteBuddy.compare', name, session_buddy_groups[trainingday_indexes[trainingday]][i][j]);
          if (name === buddyNames[i][j]) {
            buddyNames[i][j] = null;
            //console.log('onClickDeleteBuddy.remove_buddy.after', name, session_buddy_groups[trainingday_indexes[trainingday]][i][j])
          }
        }
      }
    }
    handleGAButtonClick('Clicked', 'Delete buddy');
  }

  const onSelectTrainerDropdown = (buddy_name, trainer_name) => {
    // console.log('BuddyTrainerList:onSelect', buddy_name);
    //globalBuddyTrainerMap = JSON.parse(JSON.stringify(BuddyTrainerMap));
    // console.log('BuddyTrainerList:onSelect.globalBuddyTrainerMap', buddy_name, globalBuddyTrainerMap);
    globalBuddyTrainerMap[buddy_name] = trainer_name;
    setBuddyTrainerMap(globalBuddyTrainerMap);
    // console.log('BuddyTrainerList:onSelect.globalBuddyTrainerMap', buddy_name, Object.keys(globalBuddyTrainerMap));
    //console.log('BuddyTrainerList:onSelect.BuddyTrainerMap.stringify', buddy_name, JSON.parse(JSON.stringify(BuddyTrainerMap['Hugh'])));
  }

  const controlNav = () => {
    if (isNavOpen) {
      document.getElementById("mySidenav").style.width = '0';
      document.getElementById("mySidenav").style.transform = 'translate(0px, 0px)';
      isNavOpen = false;
    } else {
      document.getElementById("mySidenav").style.width = "250px";
      const myMouseLoc = [mouseLocation[0]-125,mouseLocation[1]+20];
      document.getElementById("mySidenav").style.transform = 'translate('+myMouseLoc[0]+'px,'+myMouseLoc[1]+'px)';
      isNavOpen = true;
    }
  }

  const saveMousePos = (e) => {
    // console.log(e);
    mouseLocation = [e.clientX, e.clientY];
    // console.log(mouseLocation);
  }

  useEffect( () => {
    console.log('useEffect for mouse click. Run once only');
    document.addEventListener('click', saveMousePos, true);
  }
  , [] 
  );

  const controlHeaderDisplay = () => {
    if (isHeaderDisplayOpen) {
      document.getElementById("appHeaderDisplay").style.display = 'none';
      setHeaderDisplayOpen(false);
    } else {
      document.getElementById("appHeaderDisplay").style.display = 'block';
      setHeaderDisplayOpen(true);
    }
  }

  const saveMatch = () => {
    const saveSingleMatch = (day, buddy, trainer) => {    
      //axios.post('http://localhost:3010/match', {
      axios.post('https://techup-uplift-server.onrender.com/match', {
        day: day,
        buddy: buddy,
        trainer: trainer
      })
      .then(response => {
        console.log('saveMatch response.data', response);
        // setMatchRecords(response.data);
      })
      .catch(error => {
        console.log('saveMatch .catch', error);
        console.error(error);
      });
    }

    console.log('saveMatch', buddyTrainerMap);
    for (let rec in buddyTrainerMap){
        console.log('saveMatch map rec', rec, buddyTrainerMap[rec]);

        let day = trainingDay;
        let buddy = rec;
        let trainer = buddyTrainerMap[rec];
        saveSingleMatch(day, buddy, trainer);
    }
    setAppNotification('Saved. Go to History to see saved data.');
  }

  return (
    <div className="App">
      <header className="App-header">
        { isHeaderDisplayOpen ? <FaArrowCircleUp cursor='pointer' color='lightgreen' onClick={controlHeaderDisplay} /> : <FaArrowCircleDown cursor='pointer' color='lightgreen' onClick={controlHeaderDisplay} /> }
        <div id='appHeaderDisplay'>
          <h1 style={{fontSize:'50px'}}>
              Match.Me!<span style={{ fontSize: '40px', verticalAlign: '30px' }}>&#174;</span> <TiTimes style={{ verticalAlign: '-12px'}} /> NCSF Uplift <GiWeightLiftingUp style={{ fontSize: '50px'}} />
          </h1>
          <h2 style={{color: 'white'}}>Connection Creates Magic</h2>
          <h3  style={{color: 'white'}}>Ho Kok Hing</h3>
          <br/>
          Disclaimer: This website is created for learning purposes only. The information provided here should not be considered professional advice. Please note that we make no guarantees regarding the accuracy, completeness, or reliability of the contents of this website. Any actions you take based on the contents of this website are at your own risk. We are not liable for any losses or damages incurred from the use of this website.
        </div>
      </header>

      <div id="mySidenav" className="sidenav"  style={{fontWeight: 'bold'}}>
        <a onClick={onClickLeftPanelSession}>Session</a>
        <a onClick={onClickLeftPanelConfiguration}>Configuration</a>
        <a onClick={onClickLeftPanelHistory}>History</a>
        <br/><br/>
        <BiDumbbell style={{fontSize: '30px', margin: '0 5px 0 30px'}} /><BiDumbbell style={{fontSize: '30px', margin: '0 5px'}} /><BiDumbbell style={{fontSize: '30px', margin: '0 5px'}} />
        <br/>
        <a href='https://www.canva.com/design/DAGFxG4h2oI/vXuXBucZxZUNptdQV7aCDQ/view' target='_blank'onClick={onClickLeftPanelMyPresentation}>My Presentation</a>
      </div>

      <span id='menu' style={{ fontSize:'30px', cursor:'pointer', color: 'lightgreen', verticalAlign: '-30px', margin: '5px'}} onClick={controlNav}><GiHamburgerMenu /></span>
      <span style={{ fontSize:'30px', cursor:'pointer', color: 'lightgreen', verticalAlign: '-30px', margin: '5px'}} onClick={saveMatch}><IoSave /></span>

      <div>
        <span id='AppNotificationBox' style={{visibility : 'hidden'}}>Notification</span>
      </div>
 
      <div>
        <div className="Main-Centre sessionStyle">
          
          { centrePanelDisplay === "SessionPanel" &&
            <div key="session-group">
              <h2>{trainingDay} Session</h2>
              {
                daySessions.map((item, index, array) => {
                  return (
                    <SessionGroup
                      key={'session-group-' + item}
                      trainingDay={trainingDay}
                      trainingTime={item}
                      buddyNames={buddyNames[index]}
                      selectedTrainerNames={selectedTrainerNames}
                      expandState={sessionGroupExpand[index]}
                      buddyTrainerMap={buddyTrainerMap}
                      onSelectTrainerDropdown={onSelectTrainerDropdown}
                      deleteBuddy={onClickDeleteBuddy}
                      addNewBuddy={addNewBuddy} />                     
                  )
                })
              }
            </div>
          }

          { centrePanelDisplay === "ConfigurationPanel" &&
            <>
              <Configuration
                trainingDays={trainingDays}
                trainingDay={trainingDay}
                onChangeTrainingDay={onChangeTrainingDay}
                trainerNames={trainerNames}
                selectedTrainerNames={selectedTrainerNames}
                onClickTrainerNameButton={onClickTrainerNameButton}
                onClickAddNewTrainer={onClickAddNewTrainer}
              />
            </>
          }

          { centrePanelDisplay === "HistoryPanel" &&
            <MatchHistory />
          }

        </div>
      </div>
    </div>

  );
}

export default App