//import React from "react";
import { useState } from "react";

import Modal from 'react-modal';
import "./App.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { AiOutlineMinusCircle } from 'react-icons/ai'
import { IoPerson } from "react-icons/io5"
import { BsDisplay } from "react-icons/bs";
import { MdHeight } from "react-icons/md";
import { HiGlobeEuropeAfrica } from "react-icons/hi2";
import {setAppNotification, calculateModelPosition} from './modalFunction';

export default function BuddyTrainerList(props) {
    // console.log('BuddyTrainerList',props);
    // const { buddy_name, selected_trainer_names, BuddyTrainerMap, onSelectTrainerDropdown, deleteBuddy, forceUpdateParent } = props;
    //console.log('BuddyTrainerList.props', buddy_name, selected_trainer_names, BuddyTrainerMap);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenForSelectTrainer, setIsOpenForSelectTrainer] = useState(false);

    Modal.setAppElement(document.getElementById('App'));
    //Modal.setAppElement(document.getElementById(props.buddyName));



    // const calculateModelPosition = (width, height) => {
    //     let twidth=0, theight=0, mwidth=width, mheight=height;

    //     if (mwidth > window.innerWidth) mwidth = window.innerWidth - 5;
    //     if (mheight > window.innerHeight) mheight = window.innerHeight - 5;
        
    //     twidth =Math.floor((window.innerWidth - mwidth) / 2);
    //     if (twidth < 0) twidth = 0;
    //     theight =Math.floor((window.innerHeight - mheight) / 2);
    //     if (theight < 0) theight = 0;

    //     console.log('calculateModelPosition', window.innerWidth, window.innerHeight, twidth, theight, mwidth, mheight)
    //     return([twidth, theight, mwidth, mheight]);
    // };

    const modalTranslate = calculateModelPosition(250, 200);
    // console.log(modalTranslate, modalTranslate[0]);
    const customStyles = {
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: modalTranslate[2],
            // height: modalTranslate[3],
            transform: 'translate(' + modalTranslate[0] + 'px, ' + modalTranslate[1] + 'px)',
            backgroundColor: 'IndianRed',
            border: 'solid black 5px',
            borderRadius: '15px',
            fontFamily: 'Papyrus',
            fontWeight: 'bold',

            // position: 'relative',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'centre',
        },
        overlay: {
            backgroundColor: 'rgb(0 0 0 / 75%)',
        }
    };

    const modalTranslate_lightgreen = calculateModelPosition(250, 200);
    const customStyles_lightgreen = {
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: modalTranslate_lightgreen[2],
            // height: modalTranslate_lightgreen[3],
            transform: 'translate(' + modalTranslate_lightgreen[0] + 'px, ' + modalTranslate_lightgreen[1] + 'px)',
            backgroundColor: 'lightgreen',
            border: 'solid black 5px',
            borderRadius: '15px',
            fontFamily: 'Papyrus',
            fontWeight: 'bold',
            // position: 'relative',
        },
        overlay: {
            backgroundColor: 'rgb(0 0 0 / 75%)',
        }
    };

    function openModalforDeleteBuddy() {
        setIsOpen(true);
    }

    let subtitle;
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModalforDeleteBuddy(buddyName, action) {
        console.log('closeModal', buddyName, action);
        //addNewBuddy((document.getElementById('AddNewBuddyInput').value), trainingtime)
        if (action === 'delete') {
            //let e['target'].['id'] = buddyName;
            props.deleteBuddy(buddyName);
            props.forceUpdateParent(Date.now());
        }
        setIsOpen(false);
        setAppNotification('Delete Buddy ' + buddyName);
    }

    function closeModalForSelectTrainer(buddyName, action) {
        console.log('clocloseModalForSelectTrainerseModal', action, buddyName, '-', selectedTrainer );
        if (action === 'add') {
            //let e['target'].['id'] = buddyName;
            props.onSelectTrainerDropdown(buddyName, selectedTrainer);
            document.getElementById(addTrainerId).innerHTML = selectedTrainer;
            document.getElementById(addTrainerId).style.border = 'solid';
            props.forceUpdateParent(Date.now());
        }
        setIsOpenForSelectTrainer(false);
        setAppNotification('Selected Trainer ' + selectedTrainer);

    }

    /*     useEffect(() => {
            console.log('BuddyTrainerList.useEffect',
                document.getElementById('btl-dropdown-' + buddy_name).
                    getElementsByClassName('testclass')[0].
                    getElementsByClassName('Dropdown-placeholder')[0].placeholder);
            //console.log('BuddyTrainerList.useEffect', document.getElementsByTagName('Dropdown'));
            document.getElementById('btl-dropdown-' + buddy_name).
                getElementsByClassName('testclass')[0].
                getElementsByClassName('Dropdown-placeholder')[0].placeholder = 'test';
        }); */


    function onSelect(event) {
        //console.log('BuddyTrainerList:onSelect.event', event, buddy_name);
        const trainer_name = event.value;
        //console.log('BuddyTrainerList:onSelect', buddy_name, trainer_name);
        props.onSelectTrainerDropdown(props.buddyName, trainer_name);
    }

    //console.log('BuddyTrainerList:dropdown.placeholder', selected_trainer_names.includes(BuddyTrainerMap[buddy_name]) ? BuddyTrainerMap[buddy_name] : 'Select...');

    const addTrainerId = 'selected-trainer-' + props.trainingTime + '-' + props.index;
    let selectedTrainer = null;

    const onClickSelectTrainer = (e) => {
        // console.log('onClickSelectTrainer', e.target.id);
        setIsOpenForSelectTrainer(true);
    }

    const onClickModalTrainerName = (e) => {
        // const name = e.target.textContent;
        selectedTrainer = e.target.textContent;
        // console.log('onClickModalTrainerName:name', selectedTrainer);
        closeModalForSelectTrainer(props.buddyName, 'add');
    }

    // console.log('props.key', props);
    let nameId = 'buddy-' + props.trainingTime + '-' + props.index;

    return (
        <div className="recordStyle">
            <div className="buddyStyle" style={{marginBottom: '10px'}} >
                <AiOutlineMinusCircle cursor='pointer' style={{ fontSize: '1.5rem', verticalAlign: '-6px', borderRadius: '20px', backgroundColor: 'IndianRed' }} onClick={openModalforDeleteBuddy} /> <span style={{fontFamily: 'Papyrus', fontWeight: 'bold'}} id={nameId} >{props.buddyName}</span>
            </div>
            <a
                id={addTrainerId}
                style={{
                    color: 'lightgreen', fontSize: '1.5rem', borderRadius:'40px', padding:'5px 20px', cursor: 'pointer',
                    border: props.buddyTrainerMap[props.buddyName] ? 'solid' : 'dotted'
                }}
                onClick={onClickSelectTrainer}>
                { props.buddyTrainerMap[props.buddyName] ? props.buddyTrainerMap[props.buddyName] : 'trainer' }
            </a>

            <br/><br/>
            {/* <div className="trainerStyle" id={'btl-dropdown-' + props.buddyName}>
                {
                    props.buddyTrainerMap[props.buddyName] ?
                        <Dropdown style={{fontFamily: 'Papyrus', fontWeight: 'bold'}} options={props.selectedTrainerNames} onChange={onSelect} placeholder={props.buddyTrainerMap[props.buddyName]} />
                        :
                        <Dropdown options={props.selectedTrainerNames} onChange={onSelect} />
                }
            </div> */}

            {/* <div style={{ position: 'fixed', display: 'flex', justifyContent: 'center', alignItems: 'centre'}}> */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                style={customStyles}
                // className="customStylesDeleteBuddy"
                contentLabel="Confirm Delete Buddy?">

                <form>
                <div style={{textAlign: 'center'}}>
                    <span ref={(_subtitle) => (subtitle = _subtitle)} style={{ fontSize: '1.5rem' }}>
                        <font color='black'>Delete {props.buddyName}?</font>
                    </span>
                    <p/>
                    <button style={{ fontSize: '2rem', cursor: 'pointer', backgroundColor: 'IndianRed', border: 0 }} onClick={() => closeModalforDeleteBuddy(props.buddyName, 'delete')}><b>&#x2713;</b></button>&nbsp;<button style={{ fontSize: '2rem',  cursor: 'pointer', backgroundColor: 'IndianRed', border: 0 }} onClick={() => closeModalforDeleteBuddy(props.buddyName, 'cancel')}>&#x2718;</button>
                </div>
                </form>
            </Modal>

            {/* {console.log('props.selectedTrainerNames.map',props.selectedTrainerNames)} */}

            <Modal
                isOpen={modalIsOpenForSelectTrainer}
                // onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                style={customStyles_lightgreen}
                contentLabel="Assign Trainer?">
                
                <div style={{textAlign: 'center'}}>
                    <span ref={(_subtitle) => (subtitle = _subtitle)} style={{ fontSize: '1.5rem' }}>
                        <font color='black'>Trainer for {props.buddyName}?</font>
                    </span>
                    <br/>
                    {
                        // console.log('modalTranslate', modalTranslate, customStyles)
                        props.selectedTrainerNames.map((trainer) => {
                            return (
                                <button className='trainerButtonInModal'
                                style={{ fontSize: '20px', fontFamily: 'Papyrus', fontWeight: 'bold', cursor: 'pointer', 
                                margin: '5px', padding: '5px 10px', borderRadius: '10px', border: '0', boxShadow: '0 0 10px lightgray' }}
                                id={trainer} key={trainer} onClick={onClickModalTrainerName}>
                                    {trainer}
                                </button>
                            )
                        })
                    }                
                    <p></p>
                    <button style={{ fontSize: '2rem',  cursor: 'pointer', backgroundColor: 'lightgreen', border: 0 }} onClick={() => closeModalForSelectTrainer(props.buddyName, 'cancel')}>&#x2718;</button>
                </div>
            </Modal>
            {/* </div> */}
        </div>
    )
}

// <Dropdown options={selected_trainer_names} id={buddy_name} onChange={onSelect} placeholder={selected_trainer_names.includes(BuddyTrainerMap[buddy_name]) ? BuddyTrainerMap[buddy_name] : null} />
// <Dropdown className='testclass' options={selected_trainer_names} onChange={onSelect} placeholder={selected_trainer_names.includes(BuddyTrainerMap[buddy_name]) ? BuddyTrainerMap[buddy_name] : 'Select...'} />