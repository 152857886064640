import TrainingDayRadioGroup from './TrainingDayRadioGroup';
import TrainerNameButtonGroup from './TrainerNameButtonGroup';

export default function Configuration(props) {
    return (
        <div className='sessionStyle'>
            <TrainingDayRadioGroup
              trainingDays={props.trainingDays}
              trainingDay={props.trainingDay}
              onChange={props.onChangeTrainingDay}
            />
            <br/>
            <TrainerNameButtonGroup
                trainingDay={props.trainingDay}
                trainerNames={props.trainerNames}
                selectedTrainerNames={props.selectedTrainerNames}
                onClickTrainerNameButton={props.onClickTrainerNameButton}
                onClickAddNewTrainer={props.onClickAddNewTrainer}
            />
        </div>
    );
}