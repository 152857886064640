import './App.css'
import BuddyTrainerList from './BuddyTrainerList'
import React from 'react'
import Modal, { setAppElement } from 'react-modal'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { BsChevronBarExpand, BsChevronBarContract } from 'react-icons/bs'
import { GrExpand, GrContract } from 'react-icons/gr'
import {setAppNotification, calculateModelPosition} from './modalFunction'

export default function SessionGroup(props) {
    // const { trainingDay, trainingTime, buddyNames, selectedTrainerNames, buddyTrainerMap, onSelectTrainerDropdown, deleteBuddy, addNewBuddy, expandState } = props;
    //console.log('SessionGroup props', trainingday, trainingtime, buddy_names, selected_trainer_names);

    const [modalIsOpen, setIsOpen] = React.useState(false);

    // used by child component to force an re-rending to parent
    const [, setForceUpdate] = React.useState(Date.now());

    const modalTranslate = calculateModelPosition(250, 200);
    // const modalTranslate = [Math.floor((window.innerWidth - 360) / 2), Math.floor((window.innerHeight - 250) / 2)];
    // const modalTranslate = [10, Math.floor((window.innerHeight - 250) / 2)];
    const customStyles = {
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: modalTranslate[2],
            // height: modalTranslate[3],
            transform: 'translate(' + modalTranslate[0] + 'px, ' + modalTranslate[1] + 'px)',
            backgroundColor: 'lightgreen',
            border: 'solid black 5px',
            borderRadius: '15px',
            fontFamily: 'Papyrus',
            fontWeight: 'bold',
            // position: 'absolute',
        },
        overlay: {
            backgroundColor: 'rgb(0 0 0 / 75%)',
        }
    };
    //console.log("window.inner:", window.innerWidth, window.innerHeight, modalTranslate[0], modalTranslate[1]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        //console.log('closeModal', document.getElementById('AddNewBuddyInput').value)
        props.addNewBuddy((document.getElementById('AddNewBuddyInput').value), props.trainingTime);
        setIsOpen(false);
        setAppNotification('Added Buddy ' + document.getElementById('AddNewBuddyInput').value);
    }

    function closeModalWithoutAdding() {
        //console.log('closeModal', document.getElementById('AddNewBuddyInput').value)
        // props.addNewBuddy((document.getElementById('AddNewBuddyInput').value), props.trainingTime)
        setIsOpen(false)
    }

    let subtitle
    return (
        <div className="sessionStyle" >
            <h3>
                {props.trainingTime} Group &nbsp;
                {props.expandState[0]
                    ? <b><GrContract style={{ fontSize: '25px', verticalAlign: '-5px', cursor: 'pointer'}} onClick={() => props.expandState[1](!props.expandState[0])} /></b>
                    : <b><GrExpand style={{ fontSize: '25px', verticalAlign: '-5px', cursor: 'pointer'}} onClick={() => props.expandState[1](!props.expandState[0])} /></b>
                }
            </h3>
            {
                props.expandState[0] ?
                    <div>
                        <div className='TrainingDaySelectionStyle' key='buddy-trainer'>
                            {props.buddyNames.map((buddy, index) => {
                                if (buddy !== null) {
                                    return (
                                        <BuddyTrainerList
                                            index={index}
                                            buddyName={buddy}
                                            trainingTime={props.trainingTime}
                                            selectedTrainerNames={props.selectedTrainerNames}
                                            buddyTrainerMap={props.buddyTrainerMap}
                                            onSelectTrainerDropdown={props.onSelectTrainerDropdown}
                                            deleteBuddy={props.deleteBuddy}
                                            forceUpdateParent={setForceUpdate}
                                        />
                                    );
                                }
                            })}
                        </div>
                        <br/>
                        <button className="button"
                            onClick={openModal}>
                            <AiOutlinePlusCircle style={{ fontSize: '50px', verticalAlign: '-16px' }} /> Buddy for {props.trainingTime}
                        </button>
                        <br/>
                        <br/>
                    </div>
                    : null
            }

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add New Buddy"
            >
                <div style={{textAlign: 'center'}}>
                    <span ref={(_subtitle) => (subtitle = _subtitle)} style={{ fontSize: '1.5rem' }}><font color='black'>New Buddy Name?</font></span>
                    <input style={{ color: 'black', fontSize: '1.5rem', textAlign: 'center', width: '200px', borderRadius: '20px', border: 'none', padding: '10px' }} id='AddNewBuddyInput' />
                    <p/>
                    <button style={{ fontSize: '2rem', cursor: 'pointer', backgroundColor: 'lightgreen', border: 0 }} onClick={closeModal}><b>&#x2713;</b></button>
                    <button style={{ fontSize: '2rem', cursor: 'pointer', backgroundColor: 'lightgreen', border: 0 }} onClick={closeModalWithoutAdding}>&#x2718;</button>
                </div>
            </Modal>
        </div>
    );
}

