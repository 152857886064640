import './App.css'
import Modal from 'react-modal'
import React from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai'
import {setAppNotification, calculateModelPosition} from './modalFunction'

//how to import global variable from other files
// import global_variable from '../otherfiles'


function TrainerNameButtonGroup(props) {
    // const { trainingday, trainer_names, selected_trainer_names, onClick, addNewTrainer } = props;
    //console.log('TrainerNameButtonGroup.props', trainingday, trainer_names, selected_trainer_names);

    Modal.setAppElement(document.getElementById('App'));

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const modalTranslate = calculateModelPosition(250, 200);
    const customStyles = {
        content: {
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            width: modalTranslate[2],
            // height: modalTranslate[3],
            transform: 'translate(' + modalTranslate[0] + 'px, ' + modalTranslate[1] + 'px)',
            backgroundColor: 'lightgreen',
            borderRadius: '15px',
            border: 'black solid 5px',
            fontFamily: 'Papyrus',
            fontWeight: 'bold',
            // position: 'absolute',
        },
        overlay: {
            backgroundColor: 'rgb(0 0 0 / 75%)',
        }
    };
    //console.log("window.inner:", window.innerWidth, window.innerHeight, modalTranslate[0], modalTranslate[1]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        //console.log('closeModal', document.getElementById('AddNewTrainerInput').value)
        props.onClickAddNewTrainer(document.getElementById('AddNewTrainerInput').value)
        setIsOpen(false)
        setAppNotification('Added Trainer ' + document.getElementById('AddNewTrainerInput').value);
    }

    function closeModalWithoutAdding() {
        setIsOpen(false)
    }

    let subtitle

    return (
        
        <div>
            <h2>{props.trainingDay} Trainer List</h2>

            {props.trainerNames.map((item) => {
                return (
                    <button className="trainerNameButton trainerButtonInModal"
                        style={{ fontSize: '25px', fontFamily: 'Papyrus', fontWeight: 'bold',
                            opacity: props.selectedTrainerNames.includes(item) ? '1.0' : '0.5', 
                            margin: '5px', padding: '5px 10px', borderRadius: '10px' }}
                        id={item} key={item} onClick={props.onClickTrainerNameButton} >
                        {item}
                    </button>
                )
            })}

            <br/>
            <br/>
            <button className="button" id="btn-add-trainer"
                // style={{ fontSize: '25px', backgroundColor: 'lightgreen', color: 'black', cursor: 'pointer',padding: '5px 20px', borderRadius: '10px' }}
                onClick={openModal}  >
                <AiOutlinePlusCircle style={{ fontSize: '50px', verticalAlign: '-16px' }} /> Trainer for {props.trainingDay}
            </button>
            <br/>
            <br/>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add New Trainer">
                    <div style={{textAlign: 'center'}}>
                        <span
                            ref={(_subtitle) => (subtitle = _subtitle)}
                            style={{ fontSize: '1.5rem' }}>
                            <font color='black'>New Trainer Name?</font>
                        </span>
                        <input id='AddNewTrainerInput' style={{ color: 'black', fontSize: '1.5rem', textAlign: 'center', width: '200px', border: 'none', borderRadius: '20px', padding: '10px' }} />
                        <p/>
                        <button id="btn-add-trainer-add" style={{ fontSize: '2rem', cursor: 'pointer', backgroundColor: 'lightgreen', border: 0 }} onClick={closeModal}><b>&#x2713;</b></button>
                        <button style={{ fontSize: '2rem',  cursor: 'pointer', backgroundColor: 'lightgreen', border: 0 }} onClick={closeModalWithoutAdding}>&#x2718;</button>
                    </div>
            </Modal>
        </div >
    )
}

export default TrainerNameButtonGroup