import './App.css';

// const sessionRadioGroup = session_names.map((d) => { return <SessionRadioButton day={d} selectedDay={trainingday} onChange={onTrainingDayChange} /> });

function TrainingDayRadioButton(props) {
    // const { trainingDay, selectedDay, onChange } = props;
    //console.log('TrainingDayRadioButton.props', day, selectedDay);

    return (
        <div>
            <input
                type="radio"
                name="eventday"
                value={props.trainingDay}
                key={props.trainingDay}
                checked={props.trainingDay === props.selectedDay}
                onChange={props.onChange}
                style={{ margin: '0px 15px' }}
            />
            <label htmlFor={props.trainingDay}>{props.trainingDay}</label>
        </div>
    );
}

function TrainingDayRadioGroup(props) {
    // const { trainingDays, trainingDay, onChange } = props;
    //console.log('TrainingDayRadioGroup.props', trainingdays, trainingday);

    return (
        <div >
            <h2>Select Training Day</h2>
            {
                <div className="TrainingDaySelectionStyle" key="TrainingDayRadioGroup">
                    {
                        props.trainingDays.map((d) => {
                            return (
                                <TrainingDayRadioButton
                                    key={"TrainingDayRadioGroup-" + d}
                                    trainingDay={d}
                                    selectedDay={props.trainingDay}
                                    onChange={props.onChange}
                                />
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}

export default TrainingDayRadioGroup