import { useState, useEffect } from 'react';
import axios from 'axios';

function DbGetMatch() {
  const [matchRecords, setMatchRecords] = useState(null);
  console.log('DbGetMatch started');

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Content-Type-Options, Accept, X-Requested-With, Origin, Access-Control-Request-Method, Access-Control-Request-Headers",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Private-Network": true,
      "Access-Control-Max-Age": "7200",
    }
  };
  
  useEffect(() => {
    console.log('DbGetMatch useEffect');
    // axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
    
    // axios.get('http://localhost:3010/match', config)
    axios.get('https://techup-uplift-server.onrender.com/match', config)
      .then(response => {
        console.log('DbGetMatch response.data', response);
        setMatchRecords(response.data);
      })
      .catch(error => {
        console.log('DbGetMatch .catch', error);
        console.error(error);
      });
  }, []);

  
  const temp = [{"day":["Mon"],"buddy":["Buddy 1"],"trainer":["Trainer 1"]},{"day":["Mon"],"buddy":["Buddy 2"],"trainer":["Trainer 2"]}];
  // console.log('temp', temp[0].day[0], temp[1]);
  console.log('DbGetMatch matchRecords', matchRecords);
  
  return (
    <div style={{color: 'white'}}>
        {
          matchRecords === null ? 
            <><p>No record found.</p></>
          :
            <>

            {
              matchRecords.map( (rec) => {
                return (
                  <>
                  {rec.day[0]} | {rec.buddy[0]} x {rec.trainer[0]}<br/>
                  </>
                )
              })
            }
            </>
        }
    </div>
  );
}

export default DbGetMatch;
