import DbGetMatch from './Db'

export default function MatchHistory(props) {
    
    return (
        <div className='sessionStyle'>
            <p>Match History List</p>
            <DbGetMatch />
        </div>
    );
}